import React, { useEffect } from "react";
import ManifestList from "./ManifestList";
import { Spinner } from "react-bootstrap";
import * as ROUTES from "../../../constants/routes";
import { Link } from "react-router-dom";
import Pagination from "../common/Pagination";
import { connect } from "react-redux";
import {
  getAllManifests,
  getManifestsByPage,
  setManifestsPageSize,
} from "../../actions/ManifestActions";
import { setApplicationContext } from "../../actions/ApplicationContextActions";

const Manifests = ({
  manifests,
  loading,
  getAllManifests,
  current,
  count,
  pageSize,
  getManifestsByPage,
  setManifestsPageSize,
}) => {
  useEffect(() => {
    getAllManifests();
  }, [getAllManifests]);

  return (
    <div>
      <div className="settings-card">
        <div className="card-section head mb-2 d-flex flex-row justify-content-between">
          <h3>Manifests</h3>
          <Link
            to={ROUTES.HOME + ROUTES.MANIFESTS + ROUTES.CREATE}
            className="ship-label-button d-flex m-3"
          >
            Create Manifest
          </Link>
        </div>
        <div className={"card-section last"}>
          {/* TODO: errors, pagination,  */}
          {loading ? (
            <Spinner animation="border" role="status" />
          ) : (
            <ManifestList
              manifests={manifests}
              pagination={
                <Pagination
                  current={current}
                  count={count}
                  pageSize={pageSize}
                  getListByPage={(pageNo) =>
                    getManifestsByPage(null, pageNo, pageSize)
                  }
                  setPageSize={(size) => setManifestsPageSize(size)}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ manifests }) => ({
  manifests: manifests.manifestsList,
  errors: manifests.errors,
  count: manifests.manifestResponse?.count,
  current: manifests.currentPage,
  loading: manifests.loading,
  pageSize: manifests.pageSize,
});

export default connect(mapStateToProps, {
  getAllManifests,
  getManifestsByPage,
  setManifestsPageSize,
  setApplicationContext,
})(Manifests);
