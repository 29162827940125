import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import instance from "../services/axiosConfig";
import axios from "axios";

const useScript = (src) => {
  useEffect(() => {
    setTimeout(() => {
      window.fbAsyncInit = function () {
        const FB = window.FB;
        FB.init({
          appId: "783513489930921",
          autoLogAppEvents: true,
          xfbml: true,
          version: "v19.0",
        });
      };
    }, 500);
    setTimeout(() => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, 1200);
  }, [src]);
};

function WhatsappLoginButton({ storeId, style }) {
  const [currentData, setCurrentData] = useState(null);
  useEffect(() => {
    const onMessage = (event) => {
      console.log("Received message");
      console.log({ event });
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      ) {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        console.log("Received message from FB (please expand)");
        console.log(JSON.stringify(data, null, 2));
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          // if user finishes the Embedded Signup flow
          if (data.event === "FINISH") {
            const { phone_number_id, waba_id } = data.data;
            console.log(
              "Phone number ID ",
              phone_number_id,
              " WhatsApp business account ID ",
              waba_id,
            );
            setCurrentData(data.data);
            // if user cancels the Embedded Signup flow
          } else if (data.event === "CANCEL") {
            const { current_step } = data.data;
            console.warn("Cancel at ", current_step);
            // if user reports an error during the Embedded Signup flow
          } else if (data.event === "ERROR") {
            const { error_message } = data.data;
            console.error("error ", error_message);
          }
        }
      } catch {
        console.log("Non JSON Responses", event.data);
      }
    };
    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);
  useScript("https://connect.facebook.net/en_US/sdk.js");
  const onPayload = (payload) => {
    const newPayload = { ...payload, currentData };
    console.log("WOULD SEND THE FOLLOWING (please expand)");
    console.log({ payload: newPayload });
    instance
      .post("/whatsapp/login/", { payload: newPayload, shop: { id: storeId } })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <Button onClick={() => launchWhatsAppSignup(onPayload)} style={style}>
      Login with Facebook
    </Button>
  );
}

export default function WhatsappLoginPage() {
  return (
    <Row>
      <Col>
        <WhatsappLoginButton
          style={{
            backgroundColor: "#1877f2",
            border: 0,
            borderRadius: "4px",
            color: "#fff",
            cursor: "pointer",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontSize: "16px",
            fontWeight: "bold",
            height: "40px",
            padding: "0 24px",
          }}
        />
      </Col>
    </Row>
  );
}

async function launchWhatsAppSignup(onPayload) {
  const FB = window.FB;
  console.log("Launching signup");
  FB.login(
    function (response) {
      console.log("Got a FB response");
      if (response.authResponse) {
        const code = response.authResponse.code;
        const payload = {
          code,
          authResponse: response.authResponse,
        };
        onPayload(payload);
      } else {
        console.log("User cancelled login or did not fully authorize.");
      }
    },
    {
      config_id: "810302434106561",
      response_type: "code", // must be set to 'code' for System User access token
      override_default_response_type: true,
      extras: {
        setup: {},
        featureType: "coexistence",
      },
    },
  );
}
