import {
  getEligibleLabels,
  addLabelsToManifest,
  setAddedLabels,
  getManifest,
} from "../../actions/ManifestActions";
import { connect } from "react-redux";
import { LabelsList } from "./LabelsList";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import history from "../../../History";
import MessageBlock from "../common/MessageBlock";
import { parseError } from "../../actions/ActionHelpers";

const AddMoreLabels = ({
  getManifest,
  getEligibleLabels,
  addLabelsToManifest,
  setAddedLabels,
  labels,
  labelsErrors,
  labelsLoading,
  adding_labels_to_manifest,
  added_labels,
  addingLabelsToManifestError,
  gettingManifestError,
}) => {
  const { id } = useParams();
  const [labelDesc, setLabelDesc] = useState(true);

  let filter = "";
  if (labelDesc) {
    filter += "ordering=~created_at";
  } else {
    filter += "ordering=created_at";
  }

  useEffect(() => {
    getEligibleLabels(filter);
    getManifest(id);
  }, [filter]);

  const handleAddingLabels = () => {
    if (added_labels.length === 0) {
      toast("Please select Labels");
    } else {
      addLabelsToManifest(id, added_labels, () => {
        toast(
          "Added " +
            added_labels.length +
            " label" +
            (added_labels.length > 1 ? "s" : ""),
        );
        history.replace(ROUTES.HOME + ROUTES.MANIFESTS + "/" + id);
      });
    }
  };
  return (
    <div className="settings-card">
      <div
        className={
          "card-section head d-flex flex-column justify-content-start align-items-start"
        }
      >
        <div className="">
          <p className="header mb-3">Select labels for this manifest</p>
        </div>
        <div className="mx-auto">
          <LabelsList
            loading={labelsLoading}
            labels={labels}
            errors={labelsErrors}
            labelDesc={labelDesc}
            toggleLabelDesc={() => setLabelDesc(!labelDesc)}
            setter={(value) => setAddedLabels(value)}
          />
        </div>
      </div>
      <div className="card-section last d-flex flex-column justify-content-center align-items-center">
        {addingLabelsToManifestError && (
          <MessageBlock
            type={"error"}
            message={parseError(addingLabelsToManifestError)}
          />
        )}
        {gettingManifestError && (
          <MessageBlock
            type={"error"}
            message={parseError(gettingManifestError)}
          />
        )}
        <div className="d-flex flex-row col-12 justify-content-center align-items-center">
          <Link
            to={ROUTES.HOME + ROUTES.MANIFESTS + "/" + id}
            className="d-flex m-2 cancel-button"
          >
            Cancel
          </Link>
          <button
            className="filled-button m-2"
            type="submit"
            disabled={adding_labels_to_manifest}
            onClick={() => {
              handleAddingLabels();
            }}
          >
            {adding_labels_to_manifest ? (
              <Spinner animation={"border"} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ manifests }) => ({
  labels: manifests.labels,
  labelsLoading: manifests.labelsLoading,
  labelsErrors: manifests.labelsErrors,
  adding_labels_to_manifest: manifests.adding_labels_to_manifest,
  added_labels: manifests.added_labels,
  addingLabelsToManifestError: manifests.adding_labels_to_manifest_error,
  gettingManifestError: manifests.manifest_error,
});

export default connect(mapStateToProps, {
  getEligibleLabels,
  addLabelsToManifest,
  setAddedLabels,
  getManifest,
})(AddMoreLabels);
