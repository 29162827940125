import {
  CLEAR_CHECKOUT_CONFIG,
  CLEAR_CHECKOUT_CONFIG_ERRORS,
  CLEAR_PRODCUT_IMAGES,
  CLEAR_SELECTED_PRODUCT,
  CONNECT_WHATSAPP,
  CONNECT_WHATSAPP_FAILURE,
  CONNECT_WHATSAPP_SUCCESS,
  CREATE_CHECKOUTS,
  CREATE_CHECKOUTS_FAILURE,
  CREATE_CHECKOUTS_SUCCESS,
  CREATE_PRODUCTS,
  CREATE_PRODUCTS_FAILURE,
  CREATE_PRODUCTS_SUCCESS,
  GET_CHECKOUTS,
  GET_CHECKOUTS_FAILURE,
  GET_CHECKOUTS_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_SUCCESS,
  GET_SELECTED_CHECKOUTS,
  GET_SELECTED_CHECKOUTS_FAILURE,
  GET_SELECTED_CHECKOUTS_SUCCESS,
  SELECT_CHECKOUT_CONFIG,
  SELECT_PRODUCT,
  SELECT_PRODUCT_IMAGES,
  SET_CREATE_CONFIG_DATA,
  SET_CREATE_PRODUCT_DATA,
  UPLOAD_PRODCUT_IMAGES,
  UPLOAD_PRODCUT_IMAGES_FAILURE,
  UPLOAD_PRODCUT_IMAGES_SUCCESS,
} from "../../constants/types";
import instance from "../../services/axiosConfig";
import { parseError } from "./ActionHelpers";
import axios from "axios";

export const getCartConfigurations = (onSuccess) => (dispatch) => {
  dispatch({ type: GET_CHECKOUTS });

  instance
    .get("/v1/checkouts")
    .then((res) => {
      dispatch({
        type: GET_CHECKOUTS_SUCCESS,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_CHECKOUTS_FAILURE,
        payload: parseError(err),
      });
    });
};

export const getSelectedCartConfiguration = (id, onSuccess) => (dispatch) => {
  dispatch({ type: GET_SELECTED_CHECKOUTS });

  instance
    .get(`/v1/checkouts/${id}`)
    .then((res) => {
      dispatch({
        type: GET_SELECTED_CHECKOUTS_SUCCESS,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_SELECTED_CHECKOUTS_FAILURE,
        payload: parseError(err),
      });
    });
};

export const createConfigUpdateForm =
  (field, value, onSuccess) => async (dispatch) => {
    await dispatch({
      type: SET_CREATE_CONFIG_DATA,
      payload: {
        field,
        value,
      },
    });
    if (onSuccess) onSuccess();
  };

export const selectCheckoutConfig = (item, onSuccess) => async (dispatch) => {
  await dispatch({
    type: SELECT_CHECKOUT_CONFIG,
    payload: item,
  });
  if (onSuccess) onSuccess();
};

export const clearSelectedCheckout = (onSuccess) => (dispatch) => {
  dispatch({
    type: CLEAR_CHECKOUT_CONFIG,
  });
  if (onSuccess) onSuccess();
};

export const createCartConfiguration =
  (data, onSuccess) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CREATE_CHECKOUTS,
      });
      const instance_id = data?.id;
      const url = !!instance_id
        ? `/v1/checkouts/${instance_id}`
        : "/v1/checkouts";
      const method = !!instance_id ? "patch" : "post";

      instance({
        data,
        url,
        method,
      })
        .then((res) => {
          dispatch({
            type: CREATE_CHECKOUTS_SUCCESS,
            payload: res.data,
          });
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          if (err.response?.status === 400) {
            dispatch({
              type: CREATE_CHECKOUTS_FAILURE,
              payload: err.response.data,
            });
          } else {
            dispatch({
              type: CREATE_CHECKOUTS_FAILURE,
              payload: err,
            });
          }
          reject(err);
        });
    });
  };

export const clearCartConfigErrors = () => {
  return {
    type: CLEAR_CHECKOUT_CONFIG_ERRORS,
  };
};

export const getCartProducts = (shop, onSuccess) => (dispatch) => {
  dispatch({ type: GET_PRODUCTS });

  instance
    .get(`/v1/shop/${shop}/products`)
    .then((res) => {
      dispatch({
        type: GET_PRODUCTS_SUCCESS,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_PRODUCTS_FAILURE,
        payload: parseError(err),
      });
    });
};

export const selectProduct = (item, onSuccess) => (dispatch) => {
  dispatch({
    type: SELECT_PRODUCT,
    payload: item,
  });
  if (onSuccess) onSuccess();
};

export const clearSelectedProduct = (onSuccess) => (dispatch) => {
  dispatch({
    type: CLEAR_SELECTED_PRODUCT,
  });
  if (onSuccess) onSuccess();
};

export const updateProductForm = (field, value, onSuccess) => (dispatch) => {
  dispatch({
    type: SET_CREATE_PRODUCT_DATA,
    payload: {
      field,
      value,
    },
  });
};

export const createCartProduct = (data, shop, onSuccess) => (dispatch) => {
  dispatch({ type: CREATE_PRODUCTS });

  instance
    .post(`/v1/shop/${shop}/products`, data)
    .then((res) => {
      dispatch({
        type: CREATE_PRODUCTS_SUCCESS,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.status === 400) {
        dispatch({
          type: CREATE_PRODUCTS_FAILURE,
          payload: err.response.data,
        });
      } else {
        dispatch({
          type: CREATE_PRODUCTS_FAILURE,
          payload: err,
        });
      }
    });
};

export const selectProductImages = (images, onSuccess) => (dispatch) => {
  dispatch({
    type: SELECT_PRODUCT_IMAGES,
    payload: images,
  });
  if (onSuccess) onSuccess();
};

export const clearProductImages = (onSuccess) => (dispatch) => {
  dispatch({
    type: CLEAR_PRODCUT_IMAGES,
  });
  if (onSuccess) onSuccess();
};

export const uploadProductImages = (images, url, onSuccess) => (dispatch) => {
  dispatch({ type: UPLOAD_PRODCUT_IMAGES });

  axios
    .post(url, images)
    .then((res) => {
      dispatch({
        type: UPLOAD_PRODCUT_IMAGES_SUCCESS,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPLOAD_PRODCUT_IMAGES_FAILURE,
        payload: parseError(err),
      });
    });
};

export const connectWhatsApp = (data, storeId) => (dispatch) => {
  dispatch({
    type: CONNECT_WHATSAPP,
  });
  const FB = window.FB;
  console.log("Launching signup");
  console.log(data);
  FB.login(
    function (response) {
      console.log("Got a FB response");
      if (response.authResponse) {
        const code = response.authResponse.code;
        const payload = {
          code,
          authResponse: response.authResponse,
        };
        const newPayload = { ...payload, ...data };
        instance
          .post("/whatsapp/login/", {
            payload: newPayload,
            shop: { id: storeId },
          })
          .then((res) => {
            dispatch({
              type: CONNECT_WHATSAPP_SUCCESS,
            });
          })
          .catch((error) => {
            dispatch({
              type: CONNECT_WHATSAPP_FAILURE,
              payload: error,
            });
            console.error(error);
          });
      } else {
        dispatch({
          type: CONNECT_WHATSAPP_FAILURE,
          payload: "User cancelled login or did not fully authorize.",
        });
        console.log("User cancelled login or did not fully authorize.");
      }
    },
    {
      config_id: "810302434106561",
      response_type: "code", // must be set to 'code' for System User access token
      override_default_response_type: true,
      extras: {
        setup: {},
        featureType: "coexistence",
      },
    },
  );
};
