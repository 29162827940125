import {
  GET_ALL_MANIFESTS,
  GET_ALL_MANIFESTS_SUCCESS,
  GET_ALL_MANIFESTS_ERROR,
  SET_MANIFESTS_PAGE_SIZE,
  LOGOUT_SUCCESS,
  GET_DRIVERS_SUCCESS,
  GET_DRIVERS_ERROR,
  GET_DRIVERS,
  GET_LABELS_FOR_MANIFEST,
  GET_LABELS_FOR_MANIFEST_SUCCESS,
  GET_LABELS_FOR_MANIFEST_ERROR,
  SET_CREATE_MANIFEST_DATA,
  SET_INITIAL_CREATE_FORM_DATA,
  CREATE_MANIFEST,
  CREATE_MANIFEST_SUCCESS,
  CREATE_MANIFEST_ERROR,
  GETTING_MANIFEST,
  GETTING_MANIFEST_SUCCESS,
  UPDATE_MANIFEST_FIELD,
  CLEAR_UPDATE_MANIFEST_FIELDS,
  ADDING_LABELS_TO_MANIFEST,
  ADDING_LABELS_TO_MANIFEST_SUCCESS,
  ADDING_LABELS_TO_MANIFEST_ERROR,
  SET_ADDED_LABELS,
  EDIT_MANIFEST_ERROR,
  GETTING_MANIFEST_ERROR,
} from "../../constants/types";

const INITIAL_MANIFESTS_STATE = {
  loading: false,
  errors: null,
  currentManifest: {},
  manifestsList: [],
  manifestResponse: {},
  currentPage: 1,
  pageSize: 10,
  creatingManifest: false,
  manifestErrors: null,
  createFormData: {
    driver: "",
    manifest_address: null,
    note: "",
    selected_labels: [],
  },
  loadingManifestDetails: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_MANIFESTS_STATE, action) => {
  switch (action.type) {
    case GET_ALL_MANIFESTS:
      return { ...state, loading: true, errors: null };
    case GET_ALL_MANIFESTS_ERROR:
      return { ...state, loading: false, errors: action.payload.errors };
    case GET_ALL_MANIFESTS_SUCCESS:
      return {
        ...state,
        manifestResponse: action.payload.response,
        manifestsList: action.payload.list,
        currentPage: action.payload.currentPage,
        errors: null,
        loading: false,
      };
    case SET_MANIFESTS_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case GET_DRIVERS:
      return { ...state, loading: true, errors: null };
    case GET_DRIVERS_SUCCESS:
      return {
        ...state,
        loading: false,
        drivers: action.payload.drivers,
        errors: null,
      };
    case GET_DRIVERS_ERROR:
      return { ...state, loading: false, errors: action.payload.errors };
    case GET_LABELS_FOR_MANIFEST:
      return { ...state, labels_loading: true };
    case GET_LABELS_FOR_MANIFEST_SUCCESS:
      return {
        ...state,
        labelsLoading: false,
        labels: action.payload.labels,
        labelsErrors: null,
      };
    case GET_LABELS_FOR_MANIFEST_ERROR:
      return {
        ...state,
        labelsLoading: false,
        labelsErrors: action.payload.errors,
      };
    case SET_CREATE_MANIFEST_DATA:
      return {
        ...state,
        creatingManifest: false,
        manifestErrors: null,
        selectedManifest: {
          ...state.selectedManifest,
          [action.payload.field]: action.payload.value,
        },
      };
    case UPDATE_MANIFEST_FIELD:
      return {
        ...state,
        updatedManifest: {
          ...state.updatedManifest,
          [action.payload.field]: action.payload.value,
        },
      };
    case CLEAR_UPDATE_MANIFEST_FIELDS:
      return {
        ...state,
        updatedManifest: null,
        edit_manifest_error: null,
      };
    case SET_INITIAL_CREATE_FORM_DATA:
      return {
        ...state,
        selectedManifest: INITIAL_MANIFESTS_STATE.createFormData,
      };
    case CREATE_MANIFEST:
      return {
        ...state,
        creatingManifest: true,
        manifestErrors: null,
      };
    case CREATE_MANIFEST_SUCCESS:
      return {
        ...state,
        creatingManifest: false,
        manifestErrors: null,
      };
    case CREATE_MANIFEST_ERROR:
      return {
        ...state,
        creatingManifest: false,
        manifestErrors: action.payload.manifest_errors,
      };
    case GETTING_MANIFEST:
      return {
        ...state,
        loadingManifestDetails: true,
      };
    case GETTING_MANIFEST_SUCCESS:
      return {
        ...state,
        loadingManifestDetails: false,
        manifestDetails: action.payload.manifest_details,
      };
    case ADDING_LABELS_TO_MANIFEST:
      return {
        ...state,
        adding_labels_to_manifest: true,
      };
    case ADDING_LABELS_TO_MANIFEST_SUCCESS:
      return {
        ...state,
        adding_labels_to_manifest: false,
      };
    case ADDING_LABELS_TO_MANIFEST_ERROR:
      return {
        ...state,
        adding_labels_to_manifest: false,
        adding_labels_to_manifest_error: action.payload.errors,
      };
    case SET_ADDED_LABELS:
      return {
        ...state,
        added_labels: action.payload.added_labels,
      };
    case EDIT_MANIFEST_ERROR:
      return {
        ...state,
        edit_manifest_error: action.payload.errors,
      };
    case GETTING_MANIFEST_ERROR:
      return {
        ...state,
        manifest_error: action.payload.errors,
        adding_labels_to_manifest: false,
        loadingManifestDetails: false,
        manifestDetails: null,
      };
    case LOGOUT_SUCCESS:
      return INITIAL_MANIFESTS_STATE;
    default:
      return state;
  }
};
