export const EMAIL_CHANGED = "email_changed";
export const PASSWORD_CHANGED = "password_changed";
export const PASSWORD_RESET_LINK_SENT = "pass_reset_link_sent";
export const SEND_RESET_LINK = "send_pass_reset_email";
export const SEND_RESET_LINK_FAILED = "send_pass_reset_email_failed";
export const LOGIN_SUCCESS = "login_success";
export const LOGIN_FAILED = "login_failed";
export const LOGIN_RETRY = "clear_errors";
export const LOGIN_USER = "login_user";
export const UPDATE_PASSWORD = "update_password";
export const UPDATE_PASSWORD_SUCCESS = "update_password_success";
export const UPDATE_PASSWORD_FAILED = "update_password_failed";

export const SESSION_AUTH_SUCCESS = "session_auth_success";
export const SESSION_AUTH_FAILED = "session_auth_failed";
export const SESSION_AUTH_LOGOUT = "session_auth_logout";
export const FIREBASE_AUTH = "firebase_auth";
export const SESSION_AUTH = "session_auth";
export const LOGOUT_SUCCESS = "logout_success";

export const GET_REFERRAL_LINK = "get_referral_link";
export const GET_REFERRAL_FAILED = "get_referral_link_failed";
export const GET_REFERRAL_SUCCESS = "get_referral_link_success";

export const UPDATE_REFER_CODE = "update_referral_code";
export const APPLY_REFERRAL_CODE = "apply_referral_code";
export const APPLY_REFERRAL_SUCCESS = "apply_referral_code_success";
export const APPLY_REFERRAL_FAILED = "apply_referral_code_failed";

export const APPLY_PROMOTION_CODE = "apply_promotion_code";
export const APPLY_PROMOTION_SUCCESS = "apply_promotion_code_success";
export const APPLY_PROMOTION_FAILED = "apply_promotion_code_failed";

export const FETCH_ADDRS = "fetch_addresses";
export const FETCH_ADDRS_SUCCESS = "fetch_addresses_success";
export const FETCH_ADDRS_ERROR = "fetch_addresses_failed";
export const DELETE_ADDRESS = "delete_address";
export const DELETE_ADDRESS_SUCCESS = "delete_address_success";
export const CHECK_ADDRESS = "validate_address";
export const CHECK_ADDRESS_SUCCESS = "validate_address_done";
export const CHECK_ADDRESS_FAILED = "validate_address_failed";
export const CLEAR_SENDER = "clear_sender_address_form";
export const CLEAR_RECEIVER = "clear_receiver_address_form";
export const CLEAR_CHECKOUT_ADDRESS = "clear_checkout_address_form";
export const SENDER = "sender";
export const RECEIVER = "receiver";
export const PHONEBOOK = "phonebook";
export const CHECKOUT = "checkout";
export const EXCHANGE_DEFAULT = "defaultExchange";
export const EXCHANGE = "exchange";
export const MANIFEST = "manifest";
export const EDIT_MANIFEST = "edit_manifest";
export const SEARCH_ADDRESS_TEXT_ENTERED = "search_address_text_entered";
export const SET_ADDRESS_PAGE_SIZE = "set_address_page_size";

export const SENDER_ADDR_UPDATE = "update_sender_addr";
export const SENDER_ADDR_SELECT = "select_sender_addr";
export const EXCHANGE_ADDR_UPDATE = "update_exchange_addr";
export const EXCHANGE_DEFAULT_ADDR_SELECT = "select_exchange_addr";
export const EXCHANGE_ADDR_SELECT = "select_exchange_addr";
export const ADD_GIFT_SENDER_ADDR = "add_gift_sender_addr";
export const ADD_GIFT_RECEIVER_ADDR = "add_gift_receiver_addr";
export const RECEIVER_ADDR_UPDATE = "update_receiver_addr";
export const RECEIVER_ADDR_SELECT = "select_receiver_addr";
export const CHECKOUT_ADDR_UPDATE = "update_checkout_addr";
export const CHECKOUT_ADDR_SELECT = "select_checkout_addr";
export const MANIFEST_ADDR_SELECT = "select_manifest_address";
export const EDIT_MANIFEST_ADDR_SELECT = "edit_manifest_addr_select";

export const CREATE_ADDR_UPDATE = "update_create_addr";
export const SELECT_ADDR_TO_EDIT = "select_addr_to_edit";
export const CLEAR_ADDR_FORM = "clear_addr_form";
export const SET_DEFAULT_ADDRESS = "set_default_address";
export const SET_DEFAULT_ADDRESS_SUCCESS = "set_default_address_success";
export const SET_DEFAULT_ADDRESS_FAILURE = "set_default_address_failure";

export const UPDATE_SELECTED_PARCEL = "selected_parcel";
export const UPDATE_PARCEL_WEIGHT = "update_parcel_weight";
export const UPDATE_PARCEL_WEIGHT_UNITS = "update_parcel_weight_units";
export const UPDATE_PARCEL_DIMENSION = "update_parcel_dimension";
export const UPDATE_PARCEL_DECLARATION = "update_parcel_declaration";
export const UPDATE_SHIPMENT_EXTRA = "update_shipment_extra";
export const UPDATE_TEMPLATE_PARCEL = "update_template_parcel";
export const UPDATE_PARCEL_TYPE = "update_package_type";
export const CUSTOM_PACKAGE_I = 0;
export const FLAT_RATE_PACKAGE_I = 1;
export const SOFT_PACK_PACKAGE_I = 2;
export const ADD_PARCEL_ITEM = "add_parcel_item";
export const EDIT_PARCEL_ITEM = "edit_parcel_item";
export const DELETE_PARCEL_ITEM = "delete_parcel_item";
export const CLEAR_PARCEL_ITEM = "clear_parcel_item";
export const SELECT_PARCEL_ITEM = "select_parcel_item";

export const UPDATE_CUSTOMS_DECLARATION = "update_customs_declaration";
export const UPDATE_CUSTOMS_ITEMS = "update_customs_items";
export const EDIT_CUSTOMS_ITEMS = "edit_customs_items";
export const DELETE_CUSTOMS_ITEMS = "delete_customs_items";
export const UPDATE_CUSTOMS_CERTIFIED = "update_customs_certified";
export const UPDATE_CUSTOMS_SIGNER = "update_customs_signer";
export const UPDATE_CUSTOMS_CONTENT_TYPE = "update_customs_content_type";
export const UPDATE_CUSTOMS_CONTENT_EXPLANATION =
  "update_customs_content_explanation";
export const UPDATE_CUSTOMS_NONDELIVERY = "update_customs_nondelivery";
export const UPDATE_CUSTOMS_INCOTERM = "update_customs_incoterm";
export const CLEAR_CUSTOMS = "clear_customs";
export const SELECT_CUSTOMS_ITEM = "select_customs_item";

export const CHECK_RATES = "get_rates_request";
export const GET_RATES_FAILED = "get_rates_failed";
export const GET_RATES_SUCCESS = "get_rates_success";

export const SELECT_RATE = "select_rate";
export const CLEAR_SELECTED_RATE = "clear_selected_rate";
export const CLEAR_RATES = "clear_rates";

export const PURCHASE_LABEL = "purchase_label";
export const UPDATE_PURCHASE_EMAIL = "update_purchase_email";
export const PURCHASE_LABEL_SUCCESS = "purchase_label_success";
export const PURCHASE_LABEL_ERROR = "purchase_label_error";

export const GET_LABEL = "get_label";
export const GET_LABEL_SUCCESS = "get_label_success";
export const GET_ALL_LABEL_SUCCESS = "get_all_labels_success";
export const GET_LABEL_ERROR = "get_label_error";
export const SEARCH_LABEL_TEXT_ENTERED = "search_label_text_entered";
export const CLEAR_SEARCH_LABEL = "clear_search_label";
export const LABEL_CREATE_SUCCESS = "label_create_success";
export const GET_GRAPH_DATA_SUCCESS = "get_graph_data_success";
export const GET_GRAPH_DATA_FAILED = "get_graph_data_failed";
export const GET_GRAPH_DATA = "get_graph_data";
export const SET_LABELS_PAGE_SIZE = "set_labels_page_size";

export const SCHEDULE_PICKUP_CLICKED = "schedule_pickup_clicked";
export const SCHEDULE_PICKUP_SUCCESS = "schedule_pickup_success";

export const CREATE_CUSTOMS_DECLARATION = "create_customs_declaration";
export const CREATE_CUSTOMS_DECLARATION_FAILURE =
  "create_customs_declaration_failure";
export const CREATE_CUSTOMS_DECLARATION_SUCCESS =
  "create_customs_declaration_succcess";

export const UPDATE_FILE_TYPE = "update_file_type";

export const CLEAR_LABEL_CREATION_ERRORS = "clear_label_creation_errors";

export const GET_TRACK_REQUEST = "get_track_request";
export const GET_TRACK_SUCCESS = "get_track_success";
export const GET_TRACK_FAILED = "get_track_failed";

// Gift Exchange
export const CREATE_EXCHANGE_SUCCESS = "create_exchange_success";
export const JOIN_EXCHANGE = "attempt_join_exchange";
export const JOIN_EXCHANGE_FAILURE = "join_exchange_failure";
export const JOIN_EXCHANGE_SUCCESS = "join_exchange_group_success";
export const SELECT_EXCHANGE = "currently_selected_exchange";
export const UPDATE_EXCHANGE_ID = "update_exchange_id";

export const GET_EXCHANGES = "attempt_get_exchanges";
export const GET_EXCHANGES_FAILURE = "get_exchanges_failure";
export const GET_EXCHANGES_SUCCESS = "get_exchanges_group_success";

// User Details
export const GET_USER_DETAILS = "get_user_details";
export const GET_USER_DETAILS_SUCCESS = "get_user_details_success";
export const GET_USER_DETAILS_FAILURE = "get_user_details_failure";
export const DELETE_CARD = "delete_card";
export const DELETE_CARD_FAILURE = "delete_card_failure";
export const DELETE_CARD_SUCCESS = "delete_card_success";

export const ADD_CARD = "add_card";
export const ADD_CARD_SUCCESS = "add_card_success";
export const ADD_CARD_FAILURE = "add_card_failure";

export const CARD_LIST = "card_list";
export const CARD_PAY = "card_pay";
export const SET_DEFAULT_CARD = "set_default_card";
export const SET_DEFAULT_CARD_SUCCESS = "set_default_card_success";
export const SET_DEFAULT_CARD_FAILED = "set_default_card_failed";
export const CHECK_DELETE_DEFAULT_CARD = "check_delete_default_card";
export const MPESA = "mpesa";
export const CREDIT_CARD = "credit_card";
export const STRIPE = "stripe";
export const PAYSTACK = "paystack";
export const SAFARICOM = "safaricom";
export const BILLED_ALWAYS = "always";
export const BILLED_MANUALLY = "manually";

export const UPDATE_ACCOUNT_FORM_FIELD = "update_account_form_field";
export const SAVE_ACCOUNT_INFO = "save_account_info";
export const SAVE_ACCOUNT_INFO_SUCCESS = "save_account_info_success";
export const SAVE_ACCOUNT_INFO_FAILURE = "save_account_info_FAILURE";
export const GET_ACCOUNT_INFO = "get_account_info";
export const GET_ACCOUNT_INFO_SUCCESS = "get_account_info_success";
export const GET_ACCOUNT_INFO_FAILURE = "get_account_info_failure";

//Integrations

export const UPDATE_API_NAME = "update_api_name";

export const GET_TOKENS = "get_token";
export const GET_TOKENS_SUCCESS = "get_token_success";
export const GET_TOKENS_FAILURE = "get_token_failure";
export const ADD_TOKEN = "add_token";
export const REMOVE_TOKEN = "remove_token";
export const REMOVE_TOKEN_SUCCESS = "remove_token_success";
export const REMOVE_TOKEN_FAILURE = "remove_token_failure";

export const GENERATE_TOKEN = "generate_token";
export const GENERATE_TOKEN_SUCCESS = "generate_token_success";
export const GENERATE_TOKEN_FAILURE = "generate_token_failure";
export const CLEAR_NEW_TOKEN = "clear_new_token";

export const SELECT_TOKEN = "select_token";

//Mailing Actions
export const MAILING_LIST_UPDATE_EMAIL = "mailing_list_update_email";
export const MAILING_LIST_CLEAR_FROM = "mailing_list_clear_form";
export const MAILING_LIST_ADD_EMAIL = "mailing_list_add_email";
export const MAILING_LIST_ADD_EMAIL_SUCCESS = "mailing_list_add_email_success";
export const MAILING_LIST_ADD_EMAIL_FAILURE = "mailing_list_add_email_failure";
export const CONTACT_FROM_UPDATE_FIELD = "update_form_update_field";
export const CONTACT_FROM_SUBMIT = "update_form_submit";
export const CONTACT_FROM_CLEAR_FROM = "update_form_clear_from";
export const CONTACT_FROM_SUBMIT_FAILURE = "update_form_submit_failure";
export const CONTACT_FROM_SUBMIT_SUCCESS = "update_form_submit_success";
export const GET_FAQS = "get_faqs";
export const GET_FAQS_SUCCESS = "get_faqs_success";
export const GET_FAQS_FAILURE = "get_faqs_failure";

//Business Detail Actions
export const UPDATE_BUSINESS_INFO_FIELD = "update_business_info_field";
export const GET_BUSINESS_INFO = "get_business_info";
export const GET_BUSINESS_INFO_SUCCESS = "get_business_info_success";
export const GET_BUSINESS_INFO_FAILED = "get_business_info_failed";
export const SAVE_BUSINESS_INFO = "save_business_info";
export const SAVE_BUSINESS_INFO_SUCCESS = "save_business_info_success";
export const SAVE_BUSINESS_INFO_FAILED = "save_business_info_failed";
export const UPLOAD_BUSINESS_DOC = "upload_business_document";
export const UPLOAD_BUSINESS_DOC_SUCCESS = "upload_business_document_success";
export const UPLOAD_BUSINESS_DOC_FAILED = "upload_business_document_failed";
export const UPDATE_DOCUMENT_UPLOAD_PROGRESS =
  "update_document_upload_progress";
export const DOWNLOAD_BUSINESS_DOC = "download_business_document";
export const DOWNLOAD_BUSINESS_DOC_SUCCESS =
  "download_business_document_success";
export const DOWNLOAD_BUSINESS_DOC_FAILED = "download_business_document_failed";

//Store Actions
export const SHOPIFY = "SHOPIFY";
export const WOOCOMMERCE = "WOO";
export const ETSY = "ETSY";
export const MANUAL = "MANUAL";
export const CONNECT_STORE = "connect_store";
export const CONNECT_STORE_SUCCESS = "connect_store_success";
export const CONNECT_STORE_FAILURE = "connect_store_failure";
export const UPDATE_STORE_NAME = "update_store_name";
export const GET_CONNECTED_STORES = "get_connected_stores";
export const GET_CONNECTED_STORES_SUCCESS = "get_connected_stores_success";
export const GET_CONNECTED_STORES_FAILURE = "get_connected_stores_failure";
export const GET_ORDERS = "get_orders";
export const GET_ORDERS_SUCCESS = "get_orders_success";
export const GET_ORDERS_FAILURE = "get_orders_failed";
export const SELECT_ORDER = "select_order";
export const DISCONNECT_STORE = "connect_store";
export const DISCONNECT_STORE_SUCCESS = "connect_store_success";
export const DISCONNECT_STORE_FAILURE = "connect_store_failure";
export const SEARCH_ORDER_TEXT_ENTERED = "search_order_text_entered";
export const SET_ORDERS_PAGE_SIZE = "set_orders_page_size";

//Manifests Actions
export const GET_ALL_MANIFESTS = "get_all_manifests";
export const GET_ALL_MANIFESTS_SUCCESS = "get_all_manifests_success";
export const GET_ALL_MANIFESTS_ERROR = "get_all_manifests_error";
export const SET_MANIFESTS_PAGE_SIZE = "set_manifests_page_size";
export const GET_DRIVERS = "get_drivers";
export const GET_DRIVERS_SUCCESS = "get_drivers_success";
export const GET_DRIVERS_ERROR = "get_drivers_error";
export const GET_LABELS_FOR_MANIFEST = "get_labels_for_manifest";
export const GET_LABELS_FOR_MANIFEST_SUCCESS =
  "get_labels_for_manifest_success";
export const GET_LABELS_FOR_MANIFEST_ERROR = "get_labels_for_manifest_error";
export const SET_CREATE_MANIFEST_DATA = "set_create_manifest_data";
export const SET_INITIAL_CREATE_FORM_DATA = "set_initial_create_form_data";
export const CREATE_MANIFEST = "create_manifest";
export const CREATE_MANIFEST_SUCCESS = "create_manifest_success";
export const CREATE_MANIFEST_ERROR = "create_manifest_error";
export const CLEAR_MANIFEST_CONFIG = "clear_manifest_config";
export const CLEAR_MANIFEST_ADDRESS = "clear_manifest_address";
export const GETTING_MANIFEST = "getting_manifest";
export const GETTING_MANIFEST_SUCCESS = "getting_manifest_success";
export const GETTING_MANIFEST_ERROR = "getting_manifest_error";
export const UPDATE_MANIFEST_FIELD = "update_manifest_field";
export const EDITING_MANIFEST = "editing_manifest";
export const EDIT_MANIFEST_SUCCESS = "edit_manifest_success";
export const EDIT_MANIFEST_ERROR = "edit_manifest_error";
export const CLEAR_UPDATE_MANIFEST_FIELDS = "clear_update_manifest_fields";
export const ADDING_LABELS_TO_MANIFEST = "adding_labels_to_manifest";
export const ADDING_LABELS_TO_MANIFEST_SUCCESS =
  "adding_labels_to_manifest_success";
export const ADDING_LABELS_TO_MANIFEST_ERROR =
  "adding_labels_to_manifest_error";
export const SET_ADDED_LABELS = "set_added_labels";

//Label Creation Template
export const UPDATE_NEW_LABEL_TEMPLATE = "update_new_label_template";

export const UPDATE_LABEL_TEMPLATE_NAME = "update_label_template_name";
export const UPDATE_LABEL_TEMPLATE_PACKAGE_TYPE =
  "update_label_template_package_type";
export const UPDATE_LABEL_TEMPLATE_PARCEL = "udpate_label_template_parcel";
export const UPDATE_LABEL_TEMPLATE_WEIGHT = "update_label_template_weight";
export const UPDATE_LABEL_TEMPLATE_EXTRAS = "update_label_template_extras";
export const UPDATE_LABEL_TEMPLATE_DECLATRATION =
  "update_label_template_customs";
export const UPDATE_LABEL_TEMPLATE_CERTIFIED =
  "update_label_template_certified";
export const UPDATE_LABEL_TEMPLATE_CONTENT = "update_label_template_content";
export const UPDATE_LABEL_TEMPLATE_SIGNER = "update_label_template_signer";
export const UPDATE_LABEL_TEMPLATE_NON_DELIVERY =
  "update_label_template_non_delivery";
export const UPDATE_LABEL_TEMPLATE_ITEMS = "update_label_template_items";
export const UPDATE_LABEL_TEMPLATE_INCOTERM = "update_label_template_incoterm";
export const CREATE_LABEL_TEMPLATE = "create_label_template";
export const CLEAR_TEMPLATE_VALUE = "clear_template_value";
export const SELECT_LABEL_TEMPLATE = "select_label_template";
export const EDIT_LABEL_TEMPLATE = "edit_label_template";
export const CLEAR_NEW_LABEL_TEMPLATE = "clear_new_label_template";
export const CLEAR_SELECTED_LABEL_TEMPLATE = "clear_selected_label_template";
export const DELETE_TEMPLATE = "delete_template";
export const CHECK_TEMPLATE_INTRO = "check_template_intro";
export const SAVE_EDITABLE_LABEL_TEMPLATE = "save_editable_label_template";

//Application Context
export const SET_CONTEXT_RETURN_URL = "set_context_return_url";
export const SET_CONTEXT_ACTION_TYPE = "set_context_action_type";
export const SET_APPLICATION_CONTEXT = "set_application_context";
export const CLEAR_APPLICATION_CONTEXT = "clear_application_context";
export const CLOSE_PROFILE_COMPLETION = "close_profile_completion";
export const SET_USER_COUNTRY = "set_user_country";

export const GET_CHECKOUTS = "get_checkouts";
export const GET_CHECKOUTS_SUCCESS = "get_checkouts_success";
export const GET_CHECKOUTS_FAILURE = "get_checkouts_failure";
export const GET_SELECTED_CHECKOUTS = "get_selected_checkouts";
export const GET_SELECTED_CHECKOUTS_SUCCESS = "get_selected_checkouts_success";
export const GET_SELECTED_CHECKOUTS_FAILURE = "get_selected_checkouts_failure";
export const CREATE_CHECKOUTS = "create_checkouts";
export const CREATE_CHECKOUTS_SUCCESS = "create_checkouts_success";
export const CREATE_CHECKOUTS_FAILURE = "create_checkouts_failure";
export const SET_CREATE_CONFIG_DATA = "set_create_config_data";
export const SELECT_CHECKOUT_CONFIG = "select_checkout_config";
export const CLEAR_CHECKOUT_CONFIG = "clear_checkout_config";
export const CLEAR_CHECKOUT_CONFIG_ERRORS = "clear_checkout_config_errors";
export const GET_PRODUCTS = "get_products";
export const GET_PRODUCTS_SUCCESS = "get_products_success";
export const GET_PRODUCTS_FAILURE = "get_products_failure";
export const CREATE_PRODUCTS = "create_products";
export const CREATE_PRODUCTS_SUCCESS = "create_products_success";
export const CREATE_PRODUCTS_FAILURE = "create_products_failure";
export const SET_CREATE_PRODUCT_DATA = "set_create_product_data";
export const SELECT_PRODUCT = "select_product";
export const CLEAR_SELECTED_PRODUCT = "clear_selected_product";
export const SELECT_PRODUCT_IMAGES = "select_product_images";
export const UPLOAD_PRODCUT_IMAGES = "upload_product_images";
export const UPLOAD_PRODCUT_IMAGES_SUCCESS = "upload_product_images_success";
export const UPLOAD_PRODCUT_IMAGES_FAILURE = "upload_product_images_failure";
export const CLEAR_PRODCUT_IMAGES = "clear_product_images";
export const CONNECT_WHATSAPP = "connect_whatsapp";
export const CONNECT_WHATSAPP_SUCCESS = "connect_whatsapp_success";
export const CONNECT_WHATSAPP_FAILURE = "connect_whatsapp_failure";

export const SELECT_CSV_STORE = "select_csv_store";
export const CREATE_CSV_STORE = "create_csv_store";
export const CREATE_CSV_STORE_SUCCESS = "create_csv_store_success";
export const CREATE_CSV_STORE_FAILED = "create_csv_store_failed";
export const UNSELECT_SHOP_CSV = "unselect_shop_csv";
export const UPLOAD_CSV_FILE = "upload_csv_file";
export const UPLOAD_CSV_FILE_SUCCESS = "upload_csv_file_success";
export const UPLOAD_CSV_FILE_FAILED = "upload_csv_file_failed";
export const SAVE_CSV_CONFIG = "save_csv_config";
export const CLEAR_CSV_ERRORS = "clear_csv_errors";
export const GET_DOWNLOAD_ORDER_CSV = "get_download_order_csv";
export const GET_DOWNLOAD_ORDER_CSV_SUCCESS = "get_download_order_csv_success";
export const GET_DOWNLOAD_ORDER_CSV_FAILED = "get_download_order_csv_failed";
export const CLEAR_CSV_CONFIG = "clear_csv_config";

export const GET_ACCOUNTS = "get_accounts";
export const GET_ACCOUNTS_SUCCESS = "get_accounts_success";
export const GET_ACCOUNTS_FAILURE = "get_accounts_failure";
export const SELECT_ACCOUNT = "select_account";
export const GET_TRANSACTIONS = "get_transactions";
export const GET_TRANSACTIONS_SUCCESS = "get_transactions_success";
export const GET_TRANSACTIONS_FAILURE = "get_transactions_failure";
export const GET_TRANSACTIONS_DETAIL = "get_transactions_detail";
export const GET_TRANSACTIONS_DETAIL_SUCCESS =
  "get_transactions_detail_success";
export const GET_TRANSACTIONS_DETAIL_FAILURE =
  "get_transactions_detail_failure";
export const SEARCH_TRANSACTION_TEXT_ENTERED =
  "search_transaction_text_entered";
export const SET_TRANSACTIONS_PAGE_SIZE = "set_transactions_page_size";
