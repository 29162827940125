import { Dropdown } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getDrivers,
  getEligibleLabels,
  createManifestForm,
  setInitialManifestForm,
  sendCreateManifestRequest,
} from "../../actions/ManifestActions";
import { Form, Spinner } from "react-bootstrap";
import { LabelsList } from "./LabelsList";
import AddressListModal from "../Address/AddressListModal";
import { MANIFEST } from "../../../constants/types";
import { FormField } from "../common/FormField";
import * as ROUTES from "../../../constants/routes";
import { Link } from "react-router-dom";
import { formatAddress, DriverComponent, ErrorBlock } from "./helpers";
import history from "../../../History";

import { clearSelectedManifest } from "../../actions";

const CreateManifest = ({
  getDrivers,
  getEligibleLabels,
  createManifestForm,
  setInitialManifestForm,
  sendCreateManifestRequest,
  createData,
  labels,
  labelsErrors,
  labelsLoading,
  loading,
  errors,
  drivers,
  manifestAddress,
  creatingManifest,
  manifestErrors,
  clearSelectedManifest,
}) => {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [labelDesc, setLabelDesc] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [driverError, setDriverError] = useState("");
  const [labelsError, setLabelsError] = useState("");
  const [addressError, setAddressError] = useState("");

  let filter = "";
  if (labelDesc) {
    filter += "ordering=~created_at";
  } else {
    filter += "ordering=created_at";
  }

  const clearErrors = () => {
    setDriverError("");
    setLabelsError("");
    setAddressError("");
  };

  useEffect(() => {
    setInitialManifestForm();
    clearSelectedManifest();
    getDrivers();
    getEligibleLabels(filter);
    clearErrors();
  }, [filter]);

  useEffect(() => {
    setValidated(false);
  }, [createData]);

  const setter = (fieldName) => {
    return (value) => {
      setValidated(false);
      clearErrors();
      createManifestForm(fieldName, value);
    };
  };

  const handleFormSubmit = (event) => {
    const data = {
      ...createData,
      manifest_address: manifestAddress,
    };
    const form = event.currentTarget;

    let isFormValid = form.checkValidity();
    setValidated(true);
    if (!data.manifest_address?.id) {
      setAddressError("Please select an address");
      isFormValid = false;
    }
    if (!data.driver || data.driver === "") {
      setDriverError("Please select a driver");
      isFormValid = false;
    }
    if (!data.selected_labels || data.selected_labels.length === 0) {
      setLabelsError("Please select labels for the manifest");
      isFormValid = false;
    }
    if (isFormValid) {
      sendCreateManifestRequest(data, () => {
        history.replace(ROUTES.HOME + ROUTES.MANIFESTS);
      });
      clearSelectedManifest();
    }
  };

  return (
    <div>
      <Form
        noValidate
        validated={validated}
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleFormSubmit(event);
        }}
      >
        <div className="settings-card">
          <div className="card-section head mb-2 d-flex flex-column justify-content-between align-items-start">
            <h3 className="mb-4">Manifest Info</h3>
            <div className="d-flex w-100 flex-row align-content-center flex-wrap">
              <Form.Group
                controlId="manifest.driver"
                className={"d-flex flex-column col-12 col-lg-6 label"}
              >
                <Form.Label className={"d-flex flex-row align-items-start"}>
                  Driver <span className={"required"}>*</span>
                </Form.Label>
                <DriverComponent
                  loading={loading}
                  drivers={drivers}
                  selectedDriver={selectedDriver}
                  setSelectedDriver={setSelectedDriver}
                  setter={setter}
                />
                {driverError && <ErrorBlock error={driverError} />}
                {errors && <ErrorBlock error={errors} />}
              </Form.Group>

              <Form.Group
                controlId="manifest.address"
                className={"d-flex flex-column col-12 col-lg-6 label"}
              >
                <Form.Label className={"d-flex flex-row align-items-start"}>
                  Address <span className={"required"}>*</span>
                </Form.Label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    onClick={() => {
                      setShowModal(!showModal);
                    }}
                  >
                    {manifestAddress
                      ? formatAddress(manifestAddress)
                      : "Select an address"}
                  </Dropdown.Toggle>
                </Dropdown>
                {addressError && <ErrorBlock error={addressError} />}
              </Form.Group>
              <FormField
                controlId={"manifest.note"}
                className={"d-flex flex-column col-12 col-lg-6 pl-0"}
                label={"Note"}
                type={"text"}
                setter={setter("note")}
                placeholder={""}
                as={"textarea"}
                rows={3}
              />
            </div>
          </div>
          <div className={"card-section"}>
            <h3 className="mb-4">Select labels for this manifest</h3>
            <LabelsList
              loading={labelsLoading}
              labels={labels}
              errors={labelsErrors}
              labelDesc={labelDesc}
              toggleLabelDesc={() => setLabelDesc(!labelDesc)}
              setter={(value) => setter("selected_labels")(value)}
            />
            {labelsError && <ErrorBlock error={labelsError} />}
          </div>
          <div className={"card-section last d-flex flex-column"}>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Link
                to={ROUTES.HOME + ROUTES.MANIFESTS}
                className="d-flex m-2 cancel-button"
              >
                Cancel
              </Link>
              <button
                className="filled m-2"
                type="submit"
                disabled={creatingManifest}
              >
                {creatingManifest ? <Spinner animation={"border"} /> : "Create"}
              </button>
            </div>
            {manifestErrors && <ErrorBlock error={manifestErrors} />}
          </div>
        </div>
      </Form>
      {showModal && (
        <AddressListModal
          toggleList={() => {
            setShowModal(!showModal);
          }}
          showList={showModal}
          listType={MANIFEST}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ manifests, manifestAddress }) => ({
  drivers: manifests.drivers,
  loading: manifests.loading,
  errors: manifests.errors,
  labels: manifests.labels,
  labelsLoading: manifests.labelsLoading,
  labelsErrors: manifests.labelsErrors,
  createData: manifests.selectedManifest,
  manifestAddress: manifestAddress,
  creatingManifest: manifests.creatingManifest,
  manifestErrors: manifests.manifestErrors,
});

export default connect(mapStateToProps, {
  getDrivers,
  getEligibleLabels,
  createManifestForm,
  setInitialManifestForm,
  sendCreateManifestRequest,
  clearSelectedManifest,
})(CreateManifest);
