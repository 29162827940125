import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Link,
  matchPath,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import { FiArrowLeft, FiInfo } from "react-icons/fi";
import { MenuContainer } from "../common/MenuContainer";
import CheckoutsCreate from "./CheckoutsCreate";
import AddressListItem from "../Address/AddressListItem";
import {
  clearSelectedCheckout,
  connectWhatsApp,
  getCartProducts,
  getSelectedCartConfiguration,
} from "../../actions/CheckoutsActions";
import CheckoutProductsList from "./CheckoutProductsList";
import CheckoutProductCreate from "./CheckoutProductCreate";
import history from "../../../History";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import MessageBlock from "../common/MessageBlock";
import VISA from "../../../assets/visa_card.svg";
import MPESA from "../../../assets/mpesa-logo.svg";

const WHATSAPP_ALLOWED = [
  "rabee@shipshap.com",
  "subhi@shipshap.com",
  "thiagor@shipshap.com",
  "aman.shadyii@gmail.com",
];

const useScript = (src) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const initTimeout = setTimeout(() => {
      window.fbAsyncInit = function () {
        const FB = window.FB;
        FB.init({
          appId: "783513489930921",
          autoLogAppEvents: true,
          xfbml: true,
          version: "v19.0",
        });
      };
    }, 500);
    const loadScriptTimeout = setTimeout(() => {
      const script = document.createElement("script");
      script.addEventListener("load", () => setLoaded(true));
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, 1200);
    return () => {
      clearTimeout(initTimeout);
      clearTimeout(loadScriptTimeout);
    };
  }, [src]);

  return loaded;
};

const onMessage = (event, setData) => {
  console.log("Received message");
  console.log({ event });
  if (
    event.origin !== "https://www.facebook.com" &&
    event.origin !== "https://web.facebook.com"
  ) {
    return;
  }
  try {
    const data = JSON.parse(event.data);
    console.log("Received message from FB (please expand)");
    console.log(JSON.stringify(data, null, 2));
    if (data.type === "WA_EMBEDDED_SIGNUP") {
      // if user finishes the Embedded Signup flow
      if (data.event === "FINISH") {
        const { phone_number_id, waba_id } = data.data;
        console.log(
          "Phone number ID ",
          phone_number_id,
          " WhatsApp business account ID ",
          waba_id,
        );
        setData(data.data);
        // if user cancels the Embedded Signup flow
      } else if (data.event === "CANCEL") {
        const { current_step } = data.data;
        console.warn("Cancel at ", current_step);
        // if user reports an error during the Embedded Signup flow
      } else if (data.event === "ERROR") {
        const { error_message } = data.data;
        console.error("error ", error_message);
      }
    }
  } catch {
    console.log("Non JSON Responses", event.data);
  }
};

const CheckoutItemDetail = ({
  item,
  getDetails,
  loading,
  connectWhatsApp,
  scriptLoaded,
  showWhatsAppConnect,
}) => {
  useEffect(() => {
    if (!item?.id) {
      history.replace(ROUTES.STORES);
    }
    getDetails();
  }, []);

  return (
    <div className={"checkout-item-detail settings-card"}>
      <div className={"card-section head"}>
        <h3>Store Detail</h3>
        <div
          className={"d-flex flex-row align-items-center justify-content-end"}
        >
          {showWhatsAppConnect && (
            <button
              className={"anchor"}
              onClick={connectWhatsApp}
              disabled={!scriptLoaded}
            >
              Connect to WhatsApp
            </button>
          )}
          <Link className={"action"} to={ROUTES.CHECKOUTS_CREATE}>
            Edit
          </Link>
        </div>
      </div>
      <div className={"card-section last"}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className={"cart-store-header"}>
              <img src={item.shop_logo} alt={item.name_to_show_customers} />
              <h2>{item.name_to_show_customers}</h2>
            </div>
            <div className={"cart-config-details"}>
              <div className={"config-detail-item"}>
                <p className={"info"}>
                  Safety Margin
                  <OverlayTrigger
                    key={"checkout-address-info"}
                    placement="auto"
                    overlay={
                      <Tooltip
                        id="checkout-address-info"
                        className={"checkout-address-info"}
                      >
                        {
                          "This field allows you to add a percentage markup on the shipping cost"
                        }
                      </Tooltip>
                    }
                    delayShow={300}
                    delayHide={150}
                  >
                    <span className={"checkout-address-info"}>
                      <FiInfo className={"warning"} />
                    </span>
                  </OverlayTrigger>
                </p>
                <p>{`${((parseFloat(item.margin_multiplier) - 1) * 100).toFixed(0)}%`}</p>
              </div>
              <div className={"config-detail-item"}>
                <p className={"info"}>
                  Currency
                  <OverlayTrigger
                    key={"checkout-address-info"}
                    placement="auto"
                    overlay={
                      <Tooltip
                        id="checkout-address-info"
                        className={"checkout-address-info"}
                      >
                        {
                          "This is the currency used for customer payments and product prices"
                        }
                      </Tooltip>
                    }
                    delayShow={300}
                    delayHide={150}
                  >
                    <span className={"checkout-address-info"}>
                      <FiInfo className={"warning"} />
                    </span>
                  </OverlayTrigger>
                </p>
                <p>{item.currency}</p>
              </div>
              {!!item.url && (
                <div className={"config-detail-item"}>
                  <p className={"info"}>Store URL</p>
                  <p>{item.url}</p>
                </div>
              )}
              <div className={"config-detail-item"}>
                <p className={"info"}>Store Address</p>
                <div>
                  {item.default_address_from ? (
                    <AddressListItem item={item.default_address_from} noEdit />
                  ) : (
                    <MessageBlock message={"No address for this checkout"} />
                  )}
                </div>
              </div>
              {item.currency === "KES" && (
                <div className={"payment-details"}>
                  <h5 className={""}>Payment Options</h5>
                  <div className={"config-detail-item "}>
                    <p className={"info"}>Supported Payment Methods</p>
                    <div className={"d-flex flex-row align-items-center"}>
                      <ul className={"d-flex flex-row align-items-center mb-0"}>
                        <li>
                          <img src={VISA} alt={"credit_card"} />
                          <p>Credit Card</p>
                        </li>
                        {item.currency === "KES" && (
                          <li>
                            <img src={MPESA} alt={"mpesa"} />
                            <p>MPESA</p>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className={"d-flex flex-row align-items-center"}>
                    <div className={"config-detail-item"}>
                      <p className={"info"}>Amount paid at checkout</p>
                      <p>{`${parseFloat(item.amount_up_front_percentage) * 100}% of the subtotal ${item.buyer_pays_delivery_fee ? "+ delivery fee" : "(excluding delivery fee)"}`}</p>
                      {/*{!item.buyer_pays_delivery_fee && <p>Free Delivery</p>}*/}
                    </div>
                    {/*<div className={"config-detail-item"}>*/}
                    {/*  <p className={"info"}>Shipping fee paid by</p>*/}
                    {/*  <p>*/}
                    {/*    {item.buyer_pays_delivery_fee ? "Buyer" : "Merchant"}*/}
                    {/*  </p>*/}
                    {/*</div>*/}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const CheckoutsDetail = (props) => {
  const [currentData, setCurrentData] = useState(null);

  const { pathname } = useLocation();
  const { id } = useParams();
  const match = (path, exact) => {
    return matchPath(pathname, { path, exact });
  };

  useEffect(() => {
    if (!props.selected?.id && !match(ROUTES.CHECKOUTS_CREATE)) {
      history.replace(ROUTES.HOME + ROUTES.STORES);
    }
    if (props.selected?.shop) props.getCartProducts(props.selected?.shop);

    window.addEventListener("message", (event) =>
      onMessage(event, setCurrentData),
    );

    return () => {
      window.removeEventListener("message", (event) =>
        onMessage(event, setCurrentData),
      );
      props.clearSelectedCheckout();
    };
  }, []);

  const scriptLoaded = useScript("https://connect.facebook.net/en_US/sdk.js");

  const isProductsPage =
    match(ROUTES.CHECKOUTS_PRODUCTS_CREATE) ||
    match(ROUTES.CHECKOUTS_PRODUCTS_DETAIL);
  const isCreatePage = match(ROUTES.CHECKOUTS_CREATE);

  return (
    <div className={"checkouts-detail-container"}>
      {isProductsPage ? (
        <Link
          to={ROUTES.CHECKOUTS_PRODUCTS}
          className={"back-action d-inline-flex flex-row align-items-center"}
        >
          <FiArrowLeft /> Back to Products
        </Link>
      ) : (
        <Link
          to={ROUTES.HOME + ROUTES.STORES}
          className={"back-action d-inline-flex flex-row align-items-center"}
        >
          <FiArrowLeft /> Back to Stores
        </Link>
      )}
      <div className={"checkouts-details d-flex flex-column flex-lg-row"}>
        {!isProductsPage && !isCreatePage && (
          <MenuContainer
          // subMenu={() => {
          //     return <div>
          //         <Link to={ROUTES.CHECKOUTS_SETTINGS}
          //               className={`${match(ROUTES.CHECKOUTS_SETTINGS, true) ? 'active' : ''}`}>Checkout
          //             Settings</Link>
          //     </div>
          // }}
          >
            <h4>Stores</h4>
            <Link
              to={`${ROUTES.CHECKOUTS_DETAILS}/${props.selected.id}`}
              className={`${!pathname.includes(ROUTES.CHECKOUTS_PRODUCTS) && match([ROUTES.CHECKOUTS_DETAILS, ROUTES.CHECKOUTS_DETAILS + "/:id", ROUTES.CHECKOUTS_CREATE], true) ? "active" : ""}`}
            >
              Store Details
            </Link>
            <Link
              to={ROUTES.CHECKOUTS_PRODUCTS}
              className={`${match(ROUTES.CHECKOUTS_PRODUCTS, false) ? "active" : ""}`}
            >
              Products
            </Link>
          </MenuContainer>
        )}

        <div
          className={`checkout-details-content ${isProductsPage || isCreatePage ? "left-align" : ""}`}
        >
          {!match(ROUTES.CHECKOUTS_CREATE) && !props.carts?.length ? (
            <Redirect to={ROUTES.HOME + ROUTES.STORES} />
          ) : (
            <Switch>
              <Route
                path={ROUTES.CHECKOUTS_CREATE}
                component={CheckoutsCreate}
              />
              <Route
                path={ROUTES.CHECKOUTS_PRODUCTS_CREATE}
                component={CheckoutProductCreate}
              />
              {/*<Route path={ROUTES.CHECKOUTS_PRODUCTS_DETAIL} component={CheckoutProductDetail}/>*/}
              <Route
                path={ROUTES.CHECKOUTS_PRODUCTS}
                exact
                component={CheckoutProductsList}
              />
              {/*<Route path={ROUTES.CHECKOUTS_SETTINGS} component={CheckoutSettings}/>*/}
              <Route
                path={""}
                render={() => {
                  return (
                    <CheckoutItemDetail
                      item={props.selected}
                      getDetails={() => props.getSelectedCartConfiguration(id)}
                      loading={props.loading}
                      connectWhatsApp={() =>
                        props.connectWhatsApp(currentData, id)
                      }
                      scriptLoaded={scriptLoaded}
                      showWhatsAppConnect={WHATSAPP_ALLOWED.includes(
                        props.userInfo?.email,
                      )}
                    />
                  );
                }}
              />
            </Switch>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ checkouts, userDetails }) => ({
  loading: checkouts.loading,
  errors: checkouts.errors,
  carts: checkouts.cartConfigs,
  selected: checkouts.selectedConfig,
  userInfo: userDetails.info,
});

export default connect(mapStateToProps, {
  getSelectedCartConfiguration,
  clearSelectedCheckout,
  getCartProducts,
  connectWhatsApp,
})(CheckoutsDetail);
